import type { SeasonCalendarType } from '@/features/season-calendar/season-calendar-type';

import { Columns } from '@/core/features';
import color from '@/core/features/styles/color';
import { bemModule, classnames } from '@/core/utils/bem-classname';
import { toTwoDigits } from '@/core/utils/number-util';

import stylesHorizontal from './season-calendar-horizontal.module.scss';
import stylesSquare from './season-calendar-square.module.scss';

export type SeasonCalendarProps = SeasonCalendarType & {
    fontColor?: 'inherit' | 'white';
    gapOverride?: 3 | 4;
    hideCalendarTitle?: boolean;
    monthFontSize?: 'font-size-12' | 'font-size-14';
    rowCount?: 1 | 2 | 3;
    titleFontSize?: 'font-size-12' | 'font-size-14' | 'font-size-16';
    titleLineHeight?: 'line-height-12' | 'line-height-17';
    titleMarginBottom?: number;
    titleTruncation?: 'ellipsis' | 'text-nowrap';
};

export default function SeasonCalendar({
    fontColor = 'inherit',
    gapOverride,
    hideCalendarTitle = false,
    monthFontSize = 'font-size-14',
    rowCount = 2,
    seasonCalendar,
    title,
    titleFontSize = 'font-size-12',
    titleLineHeight,
    titleMarginBottom = 4,
    titleTruncation,
}: SeasonCalendarProps) {
    const noSpace = rowCount !== 3;
    const gap = (gapOverride ?? rowCount === 3) ? 3 : 4;
    const count = rowCount === 3 ? 4 : rowCount === 2 ? 6 : 12;

    const styles = rowCount === 3 ? stylesSquare : stylesHorizontal;
    const bem = bemModule(styles);

    return (
        <div>
            {!hideCalendarTitle && (
                <div
                    className={classnames(styles.title, titleLineHeight, titleFontSize, titleTruncation)}
                    style={{
                        color: fontColor === 'inherit' ? 'inherit' : color('white'),
                        marginBottom: `${titleMarginBottom}px`,
                    }}
                >
                    {title}
                </div>
            )}
            <Columns
                className={styles.monthsContainer}
                count={count}
                gap={gap}
                noSpace={noSpace}
            >
                {Array.from({ length: 12 }).map((_, index) => {
                    const monthIndex = index + 1;
                    const monthInfo = seasonCalendar.find(({ month }) => month === monthIndex);
                    const isHighSeason = monthInfo?.seasonType === 'high_season';
                    const isLowSeason = monthInfo?.seasonType === 'low_season';
                    return (
                        <div
                            className={`flex-center ${monthFontSize} ${bem(styles.month, {
                                isHighSeason,
                                isLowSeason,
                            })}`}
                            key={index}
                        >
                            {toTwoDigits(monthIndex)}
                        </div>
                    );
                })}
            </Columns>
        </div>
    );
}
