/* eslint-disable no-inline-styles/no-inline-styles */
import React from 'react';

import { WindowCloseThinIcon } from '@/assets/icons/window-close-thin';
import { Button } from '@/core/features';
import color from '@/core/features/styles/color';

export type TooltipContentBoxProps = {
    content: React.ReactNode | null;
    handleClose?: () => void;
    hasCloseButton?: boolean;
    isCloseable?: boolean;
    minWidth?: number;
};

export default function TooltipContentBox({
    content,
    handleClose,
    hasCloseButton = false,
    isCloseable,
    minWidth = 320,
}: TooltipContentBoxProps) {
    const closeButtonRef = React.useRef(null);
    return (
        <div
            className={'relative cursor-default'}
            style={{
                maxHeight: hasCloseButton ? '100%' : '80vh',
                minWidth,
                overflow: hasCloseButton ? 'unset' : 'auto',
            }}
        >
            {isCloseable && (
                <div
                    className={'right-20 absolute'}
                    onClick={handleClose}
                    style={{ height: '15px', right: '20px', top: '20px', width: '15px' }}
                >
                    <button
                        className={'area-expansion-after pointer'}
                        data-qa-id={'qa-tooltip-close-button'}
                        ref={closeButtonRef}
                    >
                        <WindowCloseThinIcon
                            color={color('nobel')}
                            height={15}
                            width={15}
                        />
                    </button>
                </div>
            )}
            <div>{content}</div>
            {hasCloseButton && (
                <div
                    className={'justify-end width-100 flex'}
                    style={{ padding: '0px 20px 20px' }}
                >
                    <Button
                        fontSize={14}
                        height={40}
                        onClick={handleClose}
                        title={'übernehmen'}
                        width={122}
                    />
                </div>
            )}
        </div>
    );
}
