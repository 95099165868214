import type { IconWrapperProps } from '@/core/components/icon/icon-wrapper';
import type { SVGIconProps } from '@/core/components/icon/svg-icon';

import { IconWrapper } from '@/core/components/icon/icon-wrapper';
import SVGIcon from '@/core/components/icon/svg-icon';

export default function DeleteXDesktopBoldIcon(props: SVGIconProps & IconWrapperProps) {
    return (
        <IconWrapper {...props}>
            <SVGIcon
                {...props}
                viewBox={'0 0 12 12'}
            >
                <path
                    d={
                        'M10.74 0 6 4.74 1.26 0 0 1.26 4.74 6 0 10.74 1.26 12 6 7.26 10.74 12 12 10.74 7.26 6 12 1.26 10.74 0Z'
                    }
                    stroke={props.color}
                    strokeWidth={0}
                />
            </SVGIcon>
        </IconWrapper>
    );
}
