import type { BadgeType, BadgeWithTooltipType } from '@/core/components/badges/badge-type';
import type { ImageType } from '@/core/features/image-v2/image-type';
import type { LinkProps } from '@/core/features/link/link';

import React from 'react';

import { Badge, ConditionalLinkWrapper, HtmlTextWrapper, Image } from '@/core/features';
import { useClientUrl } from '@/core/features/app/app-atoms';
import MapTileIcon from '@/features/map/map-components/map-tiles/map-tile-icon';
import useMapOverlayHistoryState from '@/features/map/map-container/use-map-overlay-history';

type MapTileProps = {
    badge?: BadgeType | null;
    cursorStyle?: 'pointer';
    hasLivePrices?: boolean;
    height?: null | string;
    highlightedBadges?: BadgeWithTooltipType[];
    iconUrl?: string;
    image: ImageType;
    imageWidth?: number;
    isDesktopView?: boolean;
    linkType?: LinkProps['linkType'];
    linkUrl?: null | string;
    minHeight?: number;
    onImageClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    onTileLinkClick?: (event: React.MouseEvent<HTMLAnchorElement, Event>) => void;
    qaId?: string;
    subtitle?: string;
    target?: LinkProps['target'];
    title?: string;
    titleTruncation?: 'line-clamp-1' | 'line-clamp-2';
    width?: null | string;
};

export default function MapTileWrapper({
    badge,
    children,
    cursorStyle,
    hasLivePrices = false,
    height = null,
    highlightedBadges,
    iconUrl,
    image,
    imageWidth,
    isDesktopView = false,
    linkType,
    linkUrl,
    minHeight,
    onImageClick,
    onTileLinkClick,
    qaId,
    subtitle,
    target,
    title,
    titleTruncation = 'line-clamp-2',
    width = null,
}: React.PropsWithChildren<MapTileProps>) {
    const [clientUrl] = useClientUrl();
    const { closeOverlay } = useMapOverlayHistoryState();

    const onTileLinkClickWrapper = (event: React.MouseEvent<HTMLAnchorElement, Event>) => {
        // when internal linkUrl same as current page: close map overlay
        // on all other internal links: close map on next page so browser back still opens the map

        if (linkType === 'internal' && linkUrl) {
            if (linkUrl === clientUrl) {
                event.preventDefault();
                closeOverlay();
            } else {
                closeOverlay({ linkUrl });
            }
        }

        onTileLinkClick?.(event);
    };

    return (
        <ConditionalLinkWrapper
            fullWidth
            href={linkUrl}
            isFlex={true}
            linkType={linkType}
            onLinkClick={onTileLinkClickWrapper}
            target={target}
        >
            <div
                className={'grid relative bg-white border-radius-5 overflow-hidden'}
                data-qa-id={qaId}
                style={{
                    borderRadius: hasLivePrices ? '8px 3px 3px 8px' : undefined,
                    cursor: cursorStyle !== 'pointer' || !linkUrl ? 'pointer' : undefined,
                    // eslint-disable-next-line no-inline-styles/no-inline-styles
                    gridTemplateColumns: 'max-content auto',
                    height: height ?? (isDesktopView ? '177px' : '146px'),
                    minHeight,
                    minWidth: isDesktopView ? '375px' : undefined,
                    width: width ?? '100%',
                }}
            >
                <div
                    className={'flex relative'}
                    onClick={onImageClick}
                >
                    <div className={'flex relative'}>
                        <Image
                            alt={image.imageAlt}
                            container={'horizontal'}
                            height={200}
                            objectFit={'cover'}
                            preload={false}
                            url={image.imageUrl}
                            width={imageWidth ?? (isDesktopView ? 105 : 120)}
                        />
                        {highlightedBadges && highlightedBadges.length > 0 && (
                            <div
                                className={'flex-center width-100 hyphens-auto absolute top-10'}
                                style={{
                                    backgroundColor: highlightedBadges[0]?.backgroundColor,
                                    top: isDesktopView ? 0 : undefined,
                                }}
                            >
                                <div
                                    className={`flex-row justify-start flex column-gap-10 row-gap-5 max-width-100 ${isDesktopView ?? 'gap-10'} nowrap`}
                                >
                                    {highlightedBadges.map((badge, index) => {
                                        return (
                                            <div
                                                className={`flex-center width-100 padding-4 padding-x-5 ${isDesktopView ? 'font-size-14' : 'font-size-12'}`}
                                                key={`${badge.htmlText}-${index}`}
                                                style={{
                                                    backgroundColor: badge.backgroundColor,
                                                    color: badge.textColor,
                                                    // eslint-disable-next-line no-inline-styles/no-inline-styles
                                                    minHeight: '20px',
                                                }}
                                            >
                                                <HtmlTextWrapper htmlText={badge.htmlText} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {badge && (
                    <div
                        className={'badge absolute z-index-2 bottom-0 overflow-hidden'}
                        style={{
                            // eslint-disable-next-line no-inline-styles/no-inline-styles
                            borderRadius: '0 5px 0 0',
                        }}
                    >
                        <Badge
                            badge={badge}
                            borderRadius={0}
                        />
                    </div>
                )}
                <div className={'flex-start-vertical overflow-hidden'}>
                    {(title || subtitle || iconUrl) && (
                        <div
                            className={`flex-space-between-horizontal width-100 margin-bottom-5 gap-5 ${
                                isDesktopView ? 'padding-top-15 padding-x-15' : 'padding-top-10 padding-x-10'
                            }`}
                        >
                            {(title || subtitle) && (
                                <div
                                    className={`flex-start-vertical overflow-hidden ${isDesktopView ? 'gap-5' : 'gap-3'}`}
                                    style={!isDesktopView ? { margin: '-1px 0 -2px' } : undefined}
                                >
                                    {title && (
                                        <div
                                            className={`${isDesktopView ? 'font-size-16 line-height-20 bold' : 'font-size-14 bold'} ${titleTruncation ? titleTruncation : ''}`}
                                        >
                                            {title}
                                        </div>
                                    )}
                                    {subtitle && (
                                        <div
                                            className={
                                                isDesktopView
                                                    ? 'font-size-14 line-height-18 line-clamp-1'
                                                    : 'font-size-12 line-clamp-1'
                                            }
                                            style={{
                                                // eslint-disable-next-line no-inline-styles/no-inline-styles
                                                hyphens: 'none',
                                                wordBreak: isDesktopView ? 'break-all' : undefined,
                                            }}
                                        >
                                            {subtitle}
                                        </div>
                                    )}
                                </div>
                            )}
                            {iconUrl && (
                                <MapTileIcon
                                    iconSize={isDesktopView ? 35 : 32}
                                    iconUrl={iconUrl}
                                />
                            )}
                        </div>
                    )}
                    <div className={'width-100 height-100 overflow-hidden'}>{children}</div>
                </div>
            </div>
        </ConditionalLinkWrapper>
    );
}
